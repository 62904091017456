<template>
  <div>
    <CRow>
      <FlashMessage :position="'right top'" style="z-index: 2000;"></FlashMessage>
      <CCard class="col-xl-12 padding-0">
        <CCardHeader>
          <CCol xl="12" style="text-align: center;" v-if="isHLoading">
            <CSpinner
              color="info"
              style="width:1rem;height:1rem;"
            />
          </CCol>
          <CRow class="mb-2">
            <CCol tag="label" xl="1" class="col-form-label padding-0 font-08">
              Start Date:
            </CCol>
            <CCol xl="5" class="padding-0" style="padding-right: 10px;">
              <date-picker 
                v-model="fstart_date" 
                placeholder="Please fill start date"
                type="datetime" 
                value-type="format" 
                format="YYYY-MM-DD HH:mm"
                style="width: 100%;">
              </date-picker>
            </CCol>
            <CCol tag="label" xl="1" class="col-form-label padding-0 font-08">
              End Date:
            </CCol>
            <CCol xl="5" class="padding-0" style="padding-right: 10px;">
              <date-picker 
                v-model="fend_date" 
                placeholder="Please fill end date"
                type="datetime" 
                value-type="format" 
                format="YYYY-MM-DD HH:mm"
                style="width: 100%;">
              </date-picker>
            </CCol>
            <CCol xl="6" class="padding-0"><small class="form-text text-muted w-100">{{errors.start_date}}</small></CCol>
            <CCol xl="6" class="padding-0"><small class="form-text text-muted w-100">{{errors.end_date}}</small></CCol>
          </CRow>
          <CRow class="padding-row">
            <CCol xl="3">
              <CRow v-if="!isHLoading">
                <CCol tag="label" xl="3" class="col-form-label padding-0">
                  Air Base:
                </CCol>
                <CCol xl="9" class="padding-0">
                  <CSelect
                    placeholder="Please select air base"
                    :value.sync="filterairport"
                    :options="fairports"
                    size="sm"
                    class="mb-0"
                  />  
                </CCol>
              </CRow>
            </CCol>
            <CCol xl="2">
              <CRow class="pl-1">
                <CCol tag="label" xl="3" class="col-form-label padding-0">
                  Time:
                </CCol>
                <CCol xl="9" class="padding-0">
                  <CSelect
                    :value.sync="filtertime"
                    :options="timefilter"
                    size="sm"
                    class="mb-0"
                  />  
                </CCol>
              </CRow>
            </CCol>
            <CCol xl="5"></CCol>
            <CCol xl="2">
              <CRow class="float-right">
                <CCol tag="label" xl="8" class="col-form-label padding-0">
                  Items per page:
                </CCol>
                <CCol xl="4" class="padding-0">
                <CSelect
                    :value.sync="itemperpage"
                    :options="filterperpage"
                    size="sm"
                    class="mb-0"
                  />  
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody class="" style="text-align: center;">
            <CSpinner
                v-if="isLoading"
                color="info"
                style="width:3rem;height:3rem;"
            />
            <span v-if="!isLoading && this.datas.length < 1">Data Belum Ada</span>
            <CDataTable
                v-if="this.datas.length > 0"
                :hover="true"
                :striped="true"
                :border="true"
                :small="true"
                :fixed="true"
                :items="this.datas"
                :fields="fields"
                style="text-align: left; overflow-x: auto;"
                class="v-mid-tab thable-center tableof-150"
            >
            </CDataTable>
            <CPagination
              v-if="this.datas.length > 0"
              size="sm"
              :active-page.sync="currentpage"
              :pages="lastpage"
              :doubleArrows="true"
              align="end"/>
        </CCardBody>
      </CCard>
    </CRow>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: 'DataManagement',
  components: { DatePicker },
  props: {
      fields: {
        type: Array,
        default () {
            return [
                { key: 'metar', label: 'Text' },
                { key: 'observed_time', label: 'Observed Time' },
                { key: 'wind', label: 'Wind' },
                { key: 'visibility', label: 'Visibility' },
                { key: 'clouds', label: 'Clouds' },
                { key: 'temperature', label: 'Temp' },
                { key: 'dew_point', label: 'Dew Point' },
                { key: 'pressure', label: 'Pressure' },
                { key: 'runway', label: 'Runway' },
            ]
        }
      },
  },
  data () {
    return {
        isLoading: false, isHLoading: false,
        tableconfig: {
          totalperpage: 15,
          pagenumber: 1,
          filter: {
            latest_hour: '',
            airport_id: '',
            start_date: '',
            end_date: ''
          },
          _method: 'GET'
        },
        timefilter: [
          {label: 'Now', value: ''}, 
          {label: 'Past 10 Hours', value: 10}
        ],
        fairports: [{ value: '', label: 'All' }],
        filterperpage: [15, 25, 50, 100],
        datas: [],
        itemperpage: 15, lastpage: 1, currentpage: 1, filtertime: '',
        filterairport: '', fstart_date: '', fend_date: '',
        errors: {
          airport_id: '',
          latest_hour: '',
          start_date: '',
          end_date: ''
        }
    }
  },
  watch: {
    currentpage: {
      handler: function (val, oldVal) {
        if(val!=oldVal) { 
          this.tableconfig.pagenumber = this.currentpage
          this.reRender() 
        }
      }
    },
    itemperpage: {
      handler: function (val, oldVal) {
        if(val!=oldVal) { 
          this.tableconfig.totalperpage = this.itemperpage
          this.reRender() 
        }
      }
    },
    filtertime: {
      handler: function (val, oldVal) {
        if(val!=oldVal) { 
          this.tableconfig.filter.latest_hour = this.filtertime
          this.reRender() 
        }
      }
    },
    filterairport: {
      handler: function (val, oldVal) {
        if(val!=oldVal) { 
          this.tableconfig.filter.airport_id = this.filterairport
          this.reRender() 
        }
      }
    },
    fstart_date: {
      handler: function (val, oldVal) {
        if(val!=oldVal) { 
          this.errors = { airport_id: '', latest_hour: '', start_date: '', end_date: '' }
          this.tableconfig.filter.start_date = this.fstart_date
          this.reRender() 
        }
      }
    },
    fend_date: {
      handler: function (val, oldVal) {
        if(val!=oldVal) { 
          this.errors = { airport_id: '', latest_hour: '', start_date: '', end_date: '' }
          this.tableconfig.filter.end_date = this.fend_date
          this.reRender() 
        }
      }
    },
  },
  created() {
    this.reRender()
    this.getAirport()
  },
  methods: {
    ...mapActions('dashboard', ['dataMGT']),
    ...mapActions('airport', ['indexAPT']),
    ...mapActions('profile', ['showPFL']),
    async reRender(){
      this.isLoading = true
      this.datas = []
      await this.dataMGT(this.tableconfig).then((response) => {
        this.datas = response.data.length > 0 ? response.data : []
        this.currentpage = response.currentpage
        this.lastpage = response.lastpage
        this.isLoading = false
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error.statusText })
        this.getError(error.data)
        this.isLoading = false
      })
    },
    async getAirport(){
      this.isHLoading = true
      await this.showPFL().then((response) => {
        response.airport_rights.forEach((item, index) => {
          this.fairports[index+1] = { value: item.id, label: item.name+" ("+item.code+")" }
        })
        this.isHLoading = false
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error })
        this.isHLoading = false
      })
    },
    getError(data){
      if(typeof(data) == 'object' && data){ if(data.errors) { 
        this.errors.airport_id = data.errors['filter.airport_id'] ? data.errors['filter.airport_id'][0] : ''
        this.errors.latest_hour = data.errors['filter.latest_hour'] ? data.errors['filter.latest_hour'][0] : ''
        this.errors.start_date = data.errors['filter.start_date'] ? data.errors['filter.start_date'][0] : ''
        this.errors.end_date = data.errors['filter.end_date'] ? data.errors['filter.end_date'][0] : ''
      }}
    }
  }
}
</script>